import * as React from 'react';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToHTML } from 'draft-js-export-html';
import getConfig from 'next/config';
import { CarouselSingle } from '@dx-ui/osc-carousel';
import type { EventContent } from '../../hooks/useEventData';
import type { ContentState } from 'draft-js';

export type IProps = {
  description: string;
  isPreviewView: boolean;
  images: EventContent['images'];
};

const {
  publicRuntimeConfig: { HILTON_ASSETS_URI },
} = getConfig();

const PreviewBody: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { description: markDownText, images, isPreviewView } = props;
  const stateFromText: ContentState = stateFromMarkdown(markDownText || '');
  const description = stateToHTML(stateFromText);
  const [uploadedImages, setUploadedImages] = React.useState<string[]>([]);

  React.useEffect(() => {
    const getImageURL = () =>
      images.map((image) => {
        const imageURL =
          image.url?.replace('/image/upload/', `/image/upload/c_mfit,h_463,q_auto:good,w_700/`) ||
          '';
        return !isPreviewView && image.status === 'pending'
          ? `${HILTON_ASSETS_URI}/modules/assets/img/common/no-photo-available.png`
          : imageURL;
      });

    if (images?.length) {
      setUploadedImages(getImageURL());
    }
  }, [images, isPreviewView]);

  const carouselImages = uploadedImages.map((item) => ({
    url: item,
    alt: item.split('/').pop(),
  }));

  return (
    <div className="flex flex-wrap">
      {images.length ? (
        <div className="w-full lg:order-2 lg:w-1/2">
          {carouselImages.length ? (
            <CarouselSingle
              images={carouselImages}
              className="[&_img]:!bg-bg aspect-[3/2] [&_img]:!object-contain"
            />
          ) : (
            <div className="aspect-[3/2]" />
          )}
        </div>
      ) : null}
      <div
        className="description-container bg-bg flex-basis-1/2 block flex-1 pr-0 pt-4 leading-normal md:pr-4 md:pt-0 lg:order-1"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default PreviewBody;
