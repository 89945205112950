import type * as Types from './types';

import {
  useMutation,
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseMutationOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
export const ContactFragmentFragmentDoc = `
    fragment ContactFragment on EventPageContact {
  emailAddressMasked
  firstName
  lastName
  phoneMasked(format: masked)
}
    `;
export const ContentFragmentFragmentDoc = `
    fragment ContentFragment on EventPageContent {
  description
  images {
    status
    url
  }
  templateType
  title
  uri
  url
  customerEditUrl
  corporateEditUrl
  friendlyUrl
}
    `;
export const EventPageFragmentFragmentDoc = `
    fragment EventPageFragment on EventPageResponse {
  data {
    __typename
    documentId
    documentStatus
    contact {
      firstName
      lastName
      emailAddressMasked
      phoneMasked(format: masked)
    }
    content {
      images {
        status
        url
      }
      description
      title
      templateType
      uri
      url
      customerEditUrl
      corporateEditUrl
      friendlyUrl
    }
  }
}
    `;
export const HotelDetailsFragmentFragmentDoc = `
    fragment HotelDetailsFragment on Hotel {
  id: ctyhocn
  brandCode
  overview {
    resortFeeDisclosureDesc
  }
  brand {
    name
  }
  name
  address {
    addressFmt
    addressLine1
    addressLine2
    city
    state
    postalCode
    country
    countryName
  }
  contactInfo {
    phoneNumber
  }
  images {
    master {
      variants(first: 1, filter: {size: xs}) {
        url
      }
      altText
    }
  }
}
    `;
export const InfoFragmentFragmentDoc = `
    fragment InfoFragment on EventPageInfo {
  ratePlans {
    ctyhocn
    ratePlanCode
    ratePlanName
    arrivalDate
    arrivalDateFmt
    departureDate
    departureDateFmt
    cutOffDate
    cutOffDateFmt
    startDate
    endDate
    hotel {
      ...HotelDetailsFragment
    }
  }
}
    ${HotelDetailsFragmentFragmentDoc}`;
export const CreateGroupReservationDocument = {
  operationName: 'createGroupReservation',
  operationString: `mutation createGroupReservation($language: String!, $propCode: String!, $input: CreateGroupReservationInput!) {
  createGroupReservation(language: $language, propCode: $propCode, input: $input) {
    data {
      arrivalDate
      arrivalDateFmt
      departureDate
      departureDateFmt
      ctyhocn: propCode
      groupDetails {
        ratePlanCode: groupCode
      }
    }
    error {
      code
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'createGroupReservation',
};
export function useCreateGroupReservationMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGroupReservationMutation,
    TError,
    [typeof CreateGroupReservationDocument, Types.CreateGroupReservationMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGroupReservationMutation,
    TError,
    [typeof CreateGroupReservationDocument, Types.CreateGroupReservationMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const GetAvailableHotelsDocument = {
  operationName: 'geocode_hotelSummaryOptions_shopMultiPropAvail',
  operationString: `query geocode_hotelSummaryOptions_shopMultiPropAvail($address: String, $language: String!, $arrivalDate: String!, $departureDate: String!, $numAdults: Int, $numRooms: Int) {
  geocode(language: $language, address: $address) {
    hotelSummaryOptions {
      _hotels {
        totalSize
      }
      hotels(first: 20) {
        ctyhocn
        propCode
        shopAvail(
          arrivalDate: $arrivalDate
          departureDate: $departureDate
          numAdults: $numAdults
          numRooms: $numRooms
          numChildren: 0
        ) {
          statusCode
          roomTypes {
            roomTypeCode
            roomRates {
              numRoomsAvail
              ratePlanCode
            }
          }
        }
      }
    }
  }
}`,
  originalOpName: 'getAvailableHotels',
};
export function useGetAvailableHotelsQuery<TData = Types.GetAvailableHotelsQuery, TError = unknown>(
  variables: Types.GetAvailableHotelsQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetAvailableHotelsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetAvailableHotelsQuery, TError, TData>({
    queryKey: [GetAvailableHotelsDocument, variables],
    ...options,
  });
}
export function serverSideGetAvailableHotelsQuery(
  queryClient: QueryClient,
  variables: Types.GetAvailableHotelsQueryVariables,
  options?: FetchQueryOptions<Types.GetAvailableHotelsQuery>
) {
  return queryClient.fetchQuery<Types.GetAvailableHotelsQuery>({
    queryKey: [GetAvailableHotelsDocument, variables],
    ...options,
  });
}

export const CreateEventPageDocument = {
  operationName: 'createEventPage',
  operationString: `mutation createEventPage($input: CreateEventPageInput!, $language: String!) {
  createEventPage(input: $input, language: $language) {
    ...EventPageFragment
  }
}

      ${EventPageFragmentFragmentDoc}`,
  originalOpName: 'createEventPage',
};
export function useCreateEventPageMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateEventPageMutation,
    TError,
    [typeof CreateEventPageDocument, Types.CreateEventPageMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateEventPageMutation,
    TError,
    [typeof CreateEventPageDocument, Types.CreateEventPageMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const GetEventRatePlanDocument = {
  operationName: 'brand_eventRatePlan_hotel',
  operationString: `query brand_eventRatePlan_hotel($language: String!, $ctyhocn: String!, $ratePlanCode: String!, $input: EventRatePlanQueryInput!) {
  eventRatePlan(
    language: $language
    ctyhocn: $ctyhocn
    ratePlanCode: $ratePlanCode
    input: $input
  ) {
    ctyhocn
    ratePlanCode
    ratePlanName
    cutOffDate
    cutOffDateFmt(format: "long", language: $language)
    arrivalDate
    arrivalDateFmt(format: "long", language: $language)
    departureDate
    departureDateFmt(format: "long", language: $language)
    startDate
    endDate
    hotel {
      ...HotelDetailsFragment
    }
  }
}

      ${HotelDetailsFragmentFragmentDoc}`,
  originalOpName: 'getEventRatePlan',
};
export function useGetEventRatePlanQuery<TData = Types.GetEventRatePlanQuery, TError = unknown>(
  variables: Types.GetEventRatePlanQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetEventRatePlanQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetEventRatePlanQuery, TError, TData>({
    queryKey: [GetEventRatePlanDocument, variables],
    ...options,
  });
}
export function serverSideGetEventRatePlanQuery(
  queryClient: QueryClient,
  variables: Types.GetEventRatePlanQueryVariables,
  options?: FetchQueryOptions<Types.GetEventRatePlanQuery>
) {
  return queryClient.fetchQuery<Types.GetEventRatePlanQuery>({
    queryKey: [GetEventRatePlanDocument, variables],
    ...options,
  });
}

export const GetFeatureTogglesDocument = {
  operationName: 'featureToggles',
  operationString: `query featureToggles($names: [String!]!) {
  featureToggles(names: $names) {
    name
    enabled
  }
}`,
  originalOpName: 'getFeatureToggles',
};
export function useGetFeatureTogglesQuery<TData = Types.GetFeatureTogglesQuery, TError = unknown>(
  variables: Types.GetFeatureTogglesQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetFeatureTogglesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetFeatureTogglesQuery, TError, TData>({
    queryKey: [GetFeatureTogglesDocument, variables],
    ...options,
  });
}
export function serverSideGetFeatureTogglesQuery(
  queryClient: QueryClient,
  variables: Types.GetFeatureTogglesQueryVariables,
  options?: FetchQueryOptions<Types.GetFeatureTogglesQuery>
) {
  return queryClient.fetchQuery<Types.GetFeatureTogglesQuery>({
    queryKey: [GetFeatureTogglesDocument, variables],
    ...options,
  });
}

export const GetEventPageDocument = {
  operationName: 'brand_eventPage_hotel',
  operationString: `query brand_eventPage_hotel($input: EventPageQueryInput!, $language: String!, $path: String!) {
  eventPage(input: $input, language: $language, path: $path) {
    documentId
    documentStatus
    info {
      ratePlans {
        ctyhocn
        arrivalDate
        arrivalDateFmt(format: "long", language: $language)
        departureDate
        departureDateFmt(format: "long", language: $language)
        cutOffDate
        cutOffDateFmt(format: "long", language: $language)
        ratePlanCode
        ratePlanName
        startDate
        endDate
        hotel {
          ...HotelDetailsFragment
        }
      }
    }
    contact {
      firstName
      lastName
      emailAddressMasked
      phoneMasked(format: masked)
    }
    content {
      images {
        status
        url
      }
      description
      title
      templateType
      uri
      url
      corporateEditUrl
      customerEditUrl
      friendlyUrl
    }
  }
}

      ${HotelDetailsFragmentFragmentDoc}`,
  originalOpName: 'getEventPage',
};
export function useGetEventPageQuery<TData = Types.GetEventPageQuery, TError = unknown>(
  variables: Types.GetEventPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetEventPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetEventPageQuery, TError, TData>({
    queryKey: [GetEventPageDocument, variables],
    ...options,
  });
}
export function serverSideGetEventPageQuery(
  queryClient: QueryClient,
  variables: Types.GetEventPageQueryVariables,
  options?: FetchQueryOptions<Types.GetEventPageQuery>
) {
  return queryClient.fetchQuery<Types.GetEventPageQuery>({
    queryKey: [GetEventPageDocument, variables],
    ...options,
  });
}

export const GetEventPageByRatePlanDocument = {
  operationName: 'brand_eventPageByRatePlan_hotel',
  operationString: `query brand_eventPageByRatePlan_hotel($ctyhocn: String!, $input: EventPageRatePlanQueryInput!, $language: String!, $ratePlanCode: String!) {
  eventPageByRatePlan(
    ctyhocn: $ctyhocn
    language: $language
    input: $input
    ratePlanCode: $ratePlanCode
  ) {
    documentId
    documentStatus
    info {
      ratePlans {
        ctyhocn
        arrivalDate
        arrivalDateFmt(format: "long", language: $language)
        departureDate
        departureDateFmt(format: "long", language: $language)
        cutOffDate
        cutOffDateFmt(format: "long", language: $language)
        ratePlanCode
        ratePlanName
        startDate
        endDate
        hotel {
          ...HotelDetailsFragment
        }
      }
    }
    contact {
      firstName
      lastName
      emailAddressMasked
      phoneMasked(format: masked)
    }
    content {
      images {
        status
        url
      }
      description
      title
      templateType
      uri
      url
    }
  }
}

      ${HotelDetailsFragmentFragmentDoc}`,
  originalOpName: 'getEventPageByRatePlan',
};
export function useGetEventPageByRatePlanQuery<
  TData = Types.GetEventPageByRatePlanQuery,
  TError = unknown
>(
  variables: Types.GetEventPageByRatePlanQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetEventPageByRatePlanQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetEventPageByRatePlanQuery, TError, TData>({
    queryKey: [GetEventPageByRatePlanDocument, variables],
    ...options,
  });
}
export function serverSideGetEventPageByRatePlanQuery(
  queryClient: QueryClient,
  variables: Types.GetEventPageByRatePlanQueryVariables,
  options?: FetchQueryOptions<Types.GetEventPageByRatePlanQuery>
) {
  return queryClient.fetchQuery<Types.GetEventPageByRatePlanQuery>({
    queryKey: [GetEventPageByRatePlanDocument, variables],
    ...options,
  });
}

export const GetShopAvailDocument = {
  operationName: 'hotel_shopPropAvail',
  operationString: `query hotel_shopPropAvail($language: String!, $ctyhocn: String!, $arrivalDate: String!, $departureDate: String!, $numAdults: Int!, $specialRates: ShopSpecialRateInput!, $guestLocationCountry: String) {
  hotel(language: $language, ctyhocn: $ctyhocn) {
    shopAvail(
      input: {arrivalDate: $arrivalDate, departureDate: $departureDate, numAdults: $numAdults, specialRates: $specialRates, guestLocationCountry: $guestLocationCountry}
    ) {
      ctyhocn
      arrivalDate
      departureDate
      statusCode
      statusMessage
      roomRates(
        sort: [{by: amountAfterTax, order: asc}]
        filter: {ratePlan: {specialRateType: group}}
      ) {
        ratePlanCode
        rateAmountFmt
        roomType {
          roomTypeName
        }
        serviceChargeDetails
      }
    }
  }
}`,
  originalOpName: 'getShopAvail',
};
export function useGetShopAvailQuery<TData = Types.GetShopAvailQuery, TError = unknown>(
  variables: Types.GetShopAvailQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetShopAvailQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetShopAvailQuery, TError, TData>({
    queryKey: [GetShopAvailDocument, variables],
    ...options,
  });
}
export function serverSideGetShopAvailQuery(
  queryClient: QueryClient,
  variables: Types.GetShopAvailQueryVariables,
  options?: FetchQueryOptions<Types.GetShopAvailQuery>
) {
  return queryClient.fetchQuery<Types.GetShopAvailQuery>({
    queryKey: [GetShopAvailDocument, variables],
    ...options,
  });
}

export const GetGuestDocument = {
  operationName: 'guest',
  operationString: `query guest($guestId: BigInt!, $language: String!, $withAddress: Boolean!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    hhonors {
      hhonorsNumber
      isFamilyAndFriends
      isLongTenure10
      isTeamMember
      isOwner
      isLongTenure20
      isOwnerHGV
      summary {
        tier
        tierName
        totalPointsFmt
        totalPoints
      }
      packages {
        packageName
      }
    }
    personalinfo {
      phones(filter: {preferred: true}) {
        phoneNumberMasked(format: masked)
      }
      emails(filter: {preferred: true}) {
        emailAddressMasked
      }
      addresses(filter: {preferred: true}) @include(if: $withAddress) {
        addressLine1 @toTitleCase
        addressLine2 @toTitleCase
        city @toTitleCase
        country
        postalCode
        state
        addressFmt(format: "stacked") @toTitleCase
      }
    }
  }
}`,
  originalOpName: 'getGuest',
};
export function useGetGuestQuery<TData = Types.GetGuestQuery, TError = unknown>(
  variables: Types.GetGuestQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetGuestQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetGuestQuery, TError, TData>({
    queryKey: [GetGuestDocument, variables],
    ...options,
  });
}
export function serverSideGetGuestQuery(
  queryClient: QueryClient,
  variables: Types.GetGuestQueryVariables,
  options?: FetchQueryOptions<Types.GetGuestQuery>
) {
  return queryClient.fetchQuery<Types.GetGuestQuery>({
    queryKey: [GetGuestDocument, variables],
    ...options,
  });
}

export const PublishEventPageDocument = {
  operationName: 'publishEventPage',
  operationString: `mutation publishEventPage($language: String!, $path: String!) {
  publishEventPage(language: $language, path: $path) {
    ...EventPageFragment
  }
}

      ${EventPageFragmentFragmentDoc}`,
  originalOpName: 'publishEventPage',
};
export function usePublishEventPageMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.PublishEventPageMutation,
    TError,
    [typeof PublishEventPageDocument, Types.PublishEventPageMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.PublishEventPageMutation,
    TError,
    [typeof PublishEventPageDocument, Types.PublishEventPageMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const UpdateEventPageDocument = {
  operationName: 'updateEventPage',
  operationString: `mutation updateEventPage($input: UpdateEventPageInput!, $language: String!) {
  updateEventPage(input: $input, language: $language) {
    ...EventPageFragment
  }
}

      ${EventPageFragmentFragmentDoc}`,
  originalOpName: 'updateEventPage',
};
export function useUpdateEventPageMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateEventPageMutation,
    TError,
    [typeof UpdateEventPageDocument, Types.UpdateEventPageMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateEventPageMutation,
    TError,
    [typeof UpdateEventPageDocument, Types.UpdateEventPageMutationVariables],
    TContext
  >({
    ...options,
  });
}
