import type * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';

export type CaptionProps = {
  /**
   * Optional, provides the text content for the caption
   */
  caption?: string;
  /**
   * Optional link url, adds screen reader text for link message "opens in a new tab"
   */
  captionLink?: string;
};

/**
 * Image caption is an optional prop that is consumed by other components, this cheese does not stand alone. Can be text only or a clickable link.
 */
export const Caption: React.FC<CaptionProps> = ({ caption, captionLink }) => {
  const { t } = useTranslation('osc-caption');
  const captionClasses =
    'flex absolute top-0 right-0 items-center justify-center flex-auto text-xs mx-4 mt-4 mb-0 py-2 px-4 bg-bg-inverse text-text-overlay opacity-80 md:mx-5 font-semibold p-2 no-underline md:mt-5 md:text-sm brand-ey:font-normal brand-gu:font-normal brand-gu:font-mono brand-wa:font-serif brand-ou:font-mono brand-ou:uppercase brand-ou:tracking-wide brand-ou:font-normal';

  if (!caption && !captionLink) {
    return null;
  }

  return (
    <figcaption data-testid="caption-container">
      {captionLink ? (
        <a
          data-e2e="linkedCaption"
          data-testid="img-caption-link"
          className={cx(captionClasses, 'cursor-pointer')}
          href={captionLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {caption}
          <span className="sr-only">{t('opensInANewTab')}</span>
        </a>
      ) : (
        <div
          data-osc-product="osc-caption"
          className={cx(captionClasses, 'pointer-events-none cursor-default')}
        >
          {caption}
        </div>
      )}
    </figcaption>
  );
};
