import { PopupButton, PopupContent, Popup, usePopup } from '@dx-ui/osc-popup';
import { ControlClose } from '@dx-ui/osc-controls';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { Icon } from '@dx-ui/osc-icon';

export type InfoPopup = {
  label?: string;
  testId?: string;
} & Popup;

export const InfoPopupContent: React.FC<React.PropsWithChildren<InfoPopup>> = ({
  children,
  testId,
  label: labelProp,
}) => {
  const { closePopup } = usePopup();
  const { t } = useTranslation('osc-info-popup');
  const infoIconRef = useRef<HTMLButtonElement>(null);
  const label = labelProp || t('moreInfo');

  return (
    <>
      <PopupButton
        className="btn btn-primary-text group"
        data-testid={`popup-button-${testId}`}
        key={`popup-button-${testId}`}
        ref={infoIconRef}
      >
        <span className="sr-only">{label}</span>
        <Icon
          name="info-circle"
          variant="regular"
          size="md"
          className="size-6 group-focus-within:hidden group-hover:hidden"
        />
        <Icon
          name="info-circle"
          variant="solid"
          size="md"
          className="hidden size-6 group-focus-within:inline group-hover:inline"
        />
      </PopupButton>
      <PopupContent className="w-fit max-w-prose" data-testid={`popup-content-${testId}`}>
        <div className="flex justify-end">
          <ControlClose label={t('close', { label })} size={10} onClick={closePopup} />
        </div>
        <div className="text-text mt-1 text-left text-sm font-normal">{children}</div>
      </PopupContent>
    </>
  );
};

export const InfoPopup: React.FC<React.PropsWithChildren<InfoPopup>> = ({
  id,
  children,
  label,
  ...rest
}) => (
  <Popup id={id} {...rest}>
    <InfoPopupContent id="" label={label} testId={id}>
      {children}
    </InfoPopupContent>
  </Popup>
);

export default InfoPopup;
