import type * as React from 'react';
import getConfig from 'next/config';
import MapPinIcon from '../../components/Icons/MapPin';
import PhoneIcon from '../../components/Icons/Phone';
import { useTranslation } from 'next-i18next';
import type { Event } from '../../hooks/useEventData';

export type IHotel = {
  hotel: NonNullable<NonNullable<Event['event']>['info']>['ratePlans'][number]['hotel'];
};

export type IHotelInfo = {
  isPreviewView: boolean;
  isNoAvailError: boolean;
  isAvailBellCurveError?: boolean;
  bookURL?: string;
  hotelData: IHotel;
};

const { publicRuntimeConfig } = getConfig();
export const getPhoneNumber = (number?: string, country?: string) => {
  if (number) {
    return country === 'US'
      ? number.replace(/(\+\d{1})-(\d{3})-(\d{3})-(\d{4})/g, '$1 ($2) $3-$4')
      : number.replace(/-/g, ' ');
  }
  return number;
};

const isIntlAddress = (country?: string) => country !== 'US';

const HotelInfo: React.FC<React.PropsWithChildren<IHotelInfo>> = (props) => {
  const { t } = useTranslation();
  const hotelImage = {
    src: props?.hotelData?.hotel?.images?.master?.variants?.[0]?.url,
    altText: props?.hotelData?.hotel?.images?.master?.altText,
  };

  return (
    <div className="flex flex-1 flex-wrap gap-4 sm:flex-nowrap md:flex-col lg:flex-row">
      {hotelImage.src ? (
        <picture className="size-full sm:max-w-xs sm:flex-1">
          <img className="w-full" src={hotelImage.src} alt={hotelImage.altText || ''} />
        </picture>
      ) : (
        <section className="bg-bg-alt empty-image flex size-full flex-col items-center justify-center self-stretch justify-self-stretch">
          {t('textContent.noPhoto')}
        </section>
      )}
      <div className="flex w-full flex-col gap-4 sm:w-auto sm:flex-1">
        <div className="flex items-center">
          <picture className="mr-2 shrink-0">
            <img
              className="inline-block h-7"
              alt={`${props?.hotelData?.hotel?.brand?.name} ${t('accessibility.logo')}`}
              src={`${publicRuntimeConfig.HILTON_ASSETS_URI}/modules/assets/svgs/logos/bug/${props?.hotelData?.hotel?.brandCode}.svg`}
            />
          </picture>
          <h3 className="leading-none tracking-normal">{props?.hotelData?.hotel?.name}</h3>
        </div>
        <div className="flex">
          <MapPinIcon className="size-5 shrink-0" aria-hidden={true} {...{ focusable: false }} />
          <p className="ml-3.5 flex flex-col leading-tight">
            <span className="sr-only">{t('accessibility.address')}</span>
            {isIntlAddress(props?.hotelData?.hotel?.address?.country || '') ? (
              <>
                <span>{`${props?.hotelData?.hotel?.address?.addressLine1}`}</span>
                <span>{`${props?.hotelData?.hotel?.address?.city || ''}, ${
                  props?.hotelData?.hotel?.address?.state &&
                  props?.hotelData?.hotel?.address?.state !== 'NA'
                    ? props?.hotelData?.hotel?.address?.state
                    : ''
                } ${props?.hotelData?.hotel?.address?.postalCode || ''}`}</span>
                <span>{props?.hotelData?.hotel?.address?.countryName}</span>
              </>
            ) : (
              <>
                <span>{`${props?.hotelData?.hotel?.address?.addressLine1},`}</span>
                <span>{`${props?.hotelData?.hotel?.address?.city}, ${props?.hotelData?.hotel?.address?.state} ${props?.hotelData?.hotel?.address?.postalCode}`}</span>
                <span>{props?.hotelData?.hotel?.address?.countryName}</span>
              </>
            )}
          </p>
        </div>
        <div className="flex">
          <div className="hotel-info-phn">
            <PhoneIcon
              className="size-5"
              color="transparent"
              aria-hidden={true}
              {...{ focusable: false }}
            />
          </div>
          <p className="ml-3.5 flex items-center leading-tight">
            <span className="sr-only">{t('accessibility.phone')}</span>
            {getPhoneNumber(
              props?.hotelData?.hotel?.contactInfo?.phoneNumber || '',
              props?.hotelData?.hotel?.address?.country || ''
            )}
          </p>
        </div>
        {!props.isPreviewView ? (
          !props.isNoAvailError && !props.isAvailBellCurveError ? (
            <a
              className="btn btn-primary btn-browse leading-11 disabled:text-text-disabled disabled:border-border h-14 w-full text-center text-xl disabled:border-none"
              href={props?.bookURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('buttonsAndLinks.bookARoom')}{' '}
              <span className="sr-only">{t('accessibility.opensNewTab')}</span>
            </a>
          ) : null
        ) : (
          <button
            type="button"
            className="btn btn-primary btn-browse disabled:text-text-disabled disabled:border-border h-14 w-full text-xl disabled:border-none"
            disabled={true}
          >
            {t('buttonsAndLinks.bookARoom')}
          </button>
        )}
      </div>
    </div>
  );
};

export default HotelInfo;
